<template>
  <div>
    <nav class="navbar navbar-expand-sm navbar-light fixed-top">
      <div class="container-fluid">
        <router-link class="navbar-brand me-0" to="/">
          <img src="../assets/images/logo__metaLearning__ch.png" alt="" width="160">
        </router-link>

        <button class="navbar-toggler me-auto d-sm-none border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <li class="nav-item d-flex d-sm-none" v-if="!userName">
          <router-link class="nav-link px-0" to="/login" style="color: rgba(0, 0, 0, 0.55);">登入 / 註冊</router-link>
        </li>
        <li class="nav-item dropdown d-flex align-items-center d-sm-none" v-if="userName">
          <a class="nav-link dropdown-toggle nav-profile p-0" href="#" id="navbarDropdown-profile" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <img :src="Avatar" alt="Profile" class="rounded-circle navbar__photo">
            <span class="d-none d-md-block ps-2 fw-bold">{{ userName }}</span>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown-profile">
            <li class="dropdown-header text-center mb-2">
              <h6 class="fw-bold mb-2" style="font-size: 20px;">{{ userName }}</h6>
              <span>{{ roleToCh(role) }}</span>
            </li>
            <li><hr class="dropdown-divider"></li>
            <li>
              <router-link class="dropdown-item profile" to="/dashboard/profile">
                <i class="bi bi-person me-1"></i>個人資料
              </router-link>
            </li>
            <li><hr class="dropdown-divider"></li>
            <li>
              <router-link class="dropdown-item" to="/dashboard/courses">
              <i class="bi bi-book me-1"></i>課程
              </router-link></li>
            <li v-if="role !== 'Member'"><hr class="dropdown-divider"></li>
            <li v-if="role !== 'Member'">
              <router-link class="dropdown-item" to="/admin/home">
              <i class="bi bi-gear-wide-connected"></i>管理
              </router-link></li>
            <li v-if="role !== 'Member'"><hr class="dropdown-divider"></li>
            <li  v-if="role == 'Member'"><hr class="dropdown-divider"></li>
            <li>
              <div class="dropdown-item" @click.prevent="logout">
                <i class="bi bi-box-arrow-right me-1"></i>登出
              </div>
            </li>
          </ul>
        </li>
        <div class="collapse navbar-collapse" id="navbarSupportedContent" style="flex-grow: unset;">
          <ul class="navbar-nav me-auto mb-0 mb-lg-0 py-3 d-sm-none">
            <li class="nav-item ps-3">
              <router-link to="/all_courses" class="nav-link text-start" aria-current="page">所有課程</router-link>
            </li>
            <li class="nav-item ps-3">
              <div class="accordion" id="category_accordion">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      課程類型
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#category_accordion">
                    <div class="accordion-body">
                      <ul class="text-start">
                        <!-- <li>
                          <a class="dropdown-item" @click.prevent="goToAllCourses(1)">
                              一般課程
                          </a>
                        </li> -->
                        <li>
                          <a class="dropdown-item" @click.prevent="goToAllCourses(2)">
                              3D 繪圖
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" @click.prevent="goToAllCourses(3)">
                              學科教學
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" @click.prevent="goToAllCourses(4)">
                              電腦資訊
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" @click.prevent="goToAllCourses(6)">
                              程式設計
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <!--<li class="nav-item ps-3" v-if="role == 'Admin'">
              <router-link to="/admin/users" class="nav-link text-start" aria-current="page" href="#">使用者管理</router-link>
            </li>
            <li class="nav-item ps-3">
              <router-link to="/all_courses" class="nav-link text-start" aria-current="page" href="#">課程管理</router-link>
            </li>
            <li class="nav-item ps-3">
              <router-link to="/all_courses" class="nav-link text-start" aria-current="page" href="#">訂單管理</router-link>
            </li> -->
          </ul>
        </div>
        <div class="collapse navbar-collapse" id="navbarSupportedContent" >
          <div class="me-auto d-none d-sm-block">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link to="/all_courses" class="nav-link" aria-current="page">所有課程</router-link>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link" aria-current="page" @click.prevent="goToAllCourses(1)" href="">一般課程</a>
              </li> -->
              <li class="nav-item">
                <a class="nav-link" aria-current="page" @click.prevent="goToAllCourses(2)" href="">3D 繪圖</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" aria-current="page" @click.prevent="goToAllCourses(3)" href="">學科教學</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" aria-current="page" @click.prevent="goToAllCourses(6)" href="">程式設計</a>
              </li>
              <!-- <li class="nav-item ps-3 position-relative">
                <a class="nav-link text-start dropdown-toggle" id="navbarDropdown-course" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">課程類型</a>
                <ul class="dropdown-menu dropdown-menu-category" aria-labelledby="navbarDropdown-course">
                  <li>
                    <a class="dropdown-item" @click.prevent="goToAllCourses(1)">
                        一般課程
                    </a>
                  </li>
                   <li>
                    <a class="dropdown-item" @click.prevent="goToAllCourses(2)">
                        3D 繪圖
                    </a>
                  </li>
                   <li>
                    <a class="dropdown-item" @click.prevent="goToAllCourses(3)">
                        學科教學
                    </a>
                  </li>
                   <li>
                    <a class="dropdown-item" @click.prevent="goToAllCourses(4)">
                        電腦資訊
                    </a>
                  </li>
                </ul>
              </li> -->
            </ul>
          </div>
          <div class="ms-auto d-none d-sm-block">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
              <li class="nav-item" v-if="!userName">
                <router-link class="nav-link" to="/login">登入 / 註冊</router-link>
              </li>
              <li class="nav-item dropdown" v-if="userName" @mouseover="view = true" @mouseleave="view = false">
                <a class="nav-link dropdown-toggle nav-profile d-flex align-items-center pe-0" href="#" id="navbarDropdown-profile" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <img :src="Avatar" alt="Profile" class="rounded-circle navbar__photo">
                  <span class="d-none d-sm-block ps-2 fw-bold">{{ userName }}</span>
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown-profile" :class="{'show': view}">
                  <li class="dropdown-header text-center mb-2">
                    <h6 class="fw-bold mb-2" style="font-size: 20px;">{{ userName }}</h6>
                    <span>{{ roleToCh(role) }}</span>
                  </li>
                  <li><hr class="dropdown-divider"></li>
                  <li>
                    <router-link class="dropdown-item" to="/dashboard/profile">
                      <i class="bi bi-person"></i>
                        個人資料
                    </router-link>
                  </li>

                  <li><hr class="dropdown-divider"></li>
                  <li>
                    <router-link class="dropdown-item" to="/dashboard/courses">
                      <i class="bi bi-book"></i>
                      課程
                    </router-link>
                  </li>
                  <li  v-if="role !== 'Member'"><hr class="dropdown-divider"></li>
                  <li v-if="role !== 'Member'">
                    <router-link class="dropdown-item" to="/admin/home">
                      <i class="bi bi-gear-wide-connected"></i>
                      管理
                    </router-link>
                  </li>
                  <li  v-if="role !== 'Member'"><hr class="dropdown-divider"></li>
                  <li  v-if="role == 'Member'"><hr class="dropdown-divider"></li>
                  <li>
                    <div class="dropdown-item" @click.prevent="logout">
                      <i class="bi bi-box-arrow-right"></i>
                      登出
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Navbar',
  data() {
    return {
      view: false,
    };
  },
  methods: {
    checkExp() {
      if (this.exp < Math.round(new Date().getTime() / 1000)) {
        this.removeUser();
      }
    },
    logout() {
      // username
      // userAuth
      this.removeUser();
      this.removeUserAuth();
      this.getUserFromLocal();
      this.$router.push('/');
    },
    goToAllCourses(cid) {
      this.$router.push({ name: 'AllCourse__c', params: { category: cid } });
      this.$bus.$emit('footer: category', cid);
    },
    roleToCh(str) {
      if (str == 'Admin') {
        return '系統管理員';
      } if (str == 'Member') {
        return '一般會員';
      } if (str == 'Teacher') {
        return '課程教師';
      }
    },
    ...mapActions(['getUserFromLocal', 'removeUser', 'removeUserAuth']),
  },
  created() {
    this.getUserFromLocal();
    this.checkExp();
    this.$log.debug(this.role);
    this.$log.debug('test:', this.userName, this.Avatar, this.userId, this.role, this.exp, this.LoginProvider);
  },
  computed: {
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'LoginProvider']),
  },
};
</script>
