<template>
  <div class="admin position-relative">
    <Navbar />
    <span class="position-absolute sidebar-mb-toggle" @click="toggled = true;"><i class="bi bi-chevron-double-left"></i></span>
    <div class="container-fluid px-0">
      <div class="row">
        <Sidebar :toggled="toggled" @close="close" />
        <main role="main" class="col-md-9 ms-sm-auto col-lg-10 px-4">
          <router-view></router-view>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Totop from '../components/Totop';
import Sidebar from '../components/Sidebar';

export default {
  name: 'Dashboard',
  components: {
    Footer,
    Navbar,
    Totop,
    Sidebar,
  },
  data() {
    return {
      toggled: false,
    };
  },
  methods: {
    close() {
      this.toggled = false;
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'userAuth', 'LoginProvider']),
  },
  created() {

  },
};
</script>

<style lang="scss">
  .admin .navbar {
    position: fixed;
  }

  .sidebar-mb-toggle {
    left: .8rem;
    top: 4.5rem;
    background-color: #e4e5e6;
    border-radius: 8px;
    color: gray;
    padding: .5rem;
  }
</style>
