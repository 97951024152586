<template>
  <div class="footer py-5 text-start">
    <div class="container px-4">
      <div class="row">
        <div class="col-12 col-3 col-md-4 order-2 order-md-1 mb-4">
          <h5 class="footer__title fw-bold mb-2">課程分類</h5>
          <ul class="footer__list">
            <!-- <li class="footer__list__item mb-2" @click="goToAllCourses(1)">一般課程</li> -->
            <li class="footer__list__item mb-2" @click="goToAllCourses(2)">3D 繪圖</li>
            <li class="footer__list__item mb-2" @click="goToAllCourses(3)">學科教學</li>
            <li class="footer__list__item mb-2" @click="goToAllCourses(4)">電腦資訊</li>
          </ul>
        </div>
        <div class="col-12 col-sm-6 col-md-4 order-3 order-md-2 mb-4">

        </div>
        <div class="col-12 col-sm-6 col-md-4 order-4 order-md-3 mb-4">
          <h5 class="footer__title fw-bold mb-2">追蹤動態</h5>
          <div class="d-flex">
            <div><a href="https://www.facebook.com/DrMasterTW/"><img src="../assets/images/facebook.svg" alt=""></a></div>
            <div><a href="https://www.instagram.com/drmaster_tw/"><img src="../assets/images/instagram.svg" alt=""></a></div>
            <div><a href="https://www.youtube.com/user/DrMasterTW"><img src="../assets/images/youtube-squared.svg" alt=""></a></div>
          </div>
          <div class="mt-2"><img src="../assets/images/logo__metaLearning__bi.png" alt="" width="180"></div>
        </div>
      </div>
    </div>
    <div class="container-fluid text-center">
      <hr>
      <small class="footer__copyright">博碩數位學院 © 保留所有版權</small>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  methods: {
    goToAllCourses(cid) {
      this.$router.push({ name: 'AllCourse__c', params: { category: cid } });
      // this.$bus.$emit('footer: category', cid);
    },
  },
};
</script>
