<template>
  <div>
    <nav class="col-md-3 col-lg-2 d-none d-md-block bg-light sidebar">
      <div class="sidebar-sticky">
        <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>系統設定</span>
          <a class="d-flex align-items-center text-muted" href="#">
            <span data-feather="plus-circle"></span>
          </a>
        </h6>
        <div class="accordion" id="accordionPanelsStayOpenExample">
          <div class="accordion-item" v-if="role == 'Admin'">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button class="accordion-button" :class="{'collapsed' : $route.name !== 'Admin__Users'}" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                <i class="bi bi-person-lines-fill me-1"></i>
                使用者
              </button>
            </h2>
            <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse" :class="{'show' : $route.name == 'Admin__Users'}" aria-labelledby="panelsStayOpen-headingOne">
              <div class="accordion-body text-start">
                <ul>
                  <li><router-link class="dropdown-item" to="/admin/users"><i class="bi bi-list-ul me-1"></i>使用者列表</router-link></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item" v-if="role == 'Admin'">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
              <button class="accordion-button" :class="{'collapsed' : $route.name !== 'Admin__Courses'}" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                <i class="bi bi-book me-1"></i>課程
              </button>
            </h2>
            <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" :class="{'show' : $route.name == 'Admin__Courses'}" aria-labelledby="panelsStayOpen-headingTwo">
              <div class="accordion-body text-start">
                <ul>
                  <li><router-link class="dropdown-item mb-3" to="/admin/courses"><i class="bi bi-list-ul me-1"></i>課程列表</router-link></li>
                  <li><router-link class="dropdown-item mb-3" to="/admin/categories"><i class="bi bi-list-ul me-1"></i>課程分類列表</router-link></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item" v-if="role == 'Admin'">
            <h2 class="accordion-header" id="panelsStayOpen-headingThree">
              <button class="accordion-button" :class="{'collapsed' : $route.name !== 'Admin__Orders'}" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                <i class="bi bi-wallet-fill me-1"></i>訂單
              </button>
            </h2>
            <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" :class="{'show' : $route.name == 'Admin__Orders'}" aria-labelledby="panelsStayOpen-headingThree">
              <div class="accordion-body text-start">
                <ul>
                  <li><router-link class="dropdown-item mb-3" to="/admin/orders"><i class="bi bi-list-ul me-1"></i>訂單列表</router-link></li>
                  <li><router-link class="dropdown-item mb-3" to="/admin/profits"><i class="bi bi-list-ul me-1"></i>通路設定</router-link></li>
                  <li><router-link class="dropdown-item" to="/admin/report"><i class="bi bi-list-ul me-1"></i>報表</router-link></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item" v-if="role == 'Teacher'">
            <h2 class="accordion-header" id="panelsStayOpen-headingFour">
              <button class="accordion-button" :class="{'collapsed' : $route.name !== 'Admin__Orders'}" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                <i class="bi bi-wallet-fill me-1"></i>課程
              </button>
            </h2>
            <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse" :class="{'show' : $route.name == 'Admin__Orders'}" aria-labelledby="panelsStayOpen-headingFour">
              <div class="accordion-body text-start">
                <ul>
                  <li><router-link class="dropdown-item" to="/admin/t/courses"><i class="bi bi-list-ul me-1"></i>課程列表</router-link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <aside class="sidebar-mb d-block d-md-none bg-light" :class="{ 'toggled': toggled}">
      <span class="sidebar-mb-close" style="font-size: 1.6rem;" @click="close"><i class="bi bi-x"></i></span>
      <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 py-2 mt-4 mb-1 text-muted">
        <span>系統設定</span>
        <a class="d-flex align-items-center text-muted" href="#">
          <span data-feather="plus-circle"></span>
        </a>
      </h6>
      <div class="accordion mt-4" id="accordionPanelsStayOpenExample">
        <div class="accordion-item" v-if="role == 'Admin'">
          <h2 class="accordion-header" id="panelsStayOpen-headingOne">
            <button class="accordion-button" :class="{'collapsed' : $route.name !== 'Admin__Users'}" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
              <i class="bi bi-person-lines-fill me-1"></i>
              使用者
            </button>
          </h2>
          <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse" :class="{'show' : $route.name == 'Admin__Users'}" aria-labelledby="panelsStayOpen-headingOne">
            <div class="accordion-body text-start">
              <ul>
                <li><router-link class="dropdown-item" to="/admin/users"><i class="bi bi-list-ul me-1"></i>使用者列表</router-link></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion-item" v-if="role == 'Admin'">
          <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
            <button class="accordion-button" :class="{'collapsed' : $route.name !== 'Admin__Courses'}" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
              <i class="bi bi-book me-1"></i>課程
            </button>
          </h2>
          <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" :class="{'show' : $route.name == 'Admin__Courses'}" aria-labelledby="panelsStayOpen-headingTwo">
            <div class="accordion-body text-start">
              <ul>
                <li><router-link class="dropdown-item mb-3" to="/admin/courses"><i class="bi bi-list-ul me-1"></i>課程列表</router-link></li>
                <li><router-link class="dropdown-item" to="/admin/categories"><i class="bi bi-list-ul me-1"></i>課程分類列表</router-link></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion-item" v-if="role == 'Admin'">
          <h2 class="accordion-header" id="panelsStayOpen-headingThree">
            <button class="accordion-button" :class="{'collapsed' : $route.name !== 'Admin__Orders'}" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
              <i class="bi bi-wallet-fill me-1"></i>訂單
            </button>
          </h2>
          <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" :class="{'show' : $route.name == 'Admin__Orders'}" aria-labelledby="panelsStayOpen-headingThree">
            <div class="accordion-body text-start">
              <ul>
                <li><router-link class="dropdown-item mb-3" to="/admin/orders"><i class="bi bi-list-ul me-1"></i>訂單列表</router-link></li>
                <li><router-link class="dropdown-item mb-3" to="/admin/profits"><i class="bi bi-list-ul me-1"></i>通路設定</router-link></li>
                <li><router-link class="dropdown-item" to="/admin/report"><i class="bi bi-list-ul me-1"></i>報表</router-link></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion-item" v-if="role == 'Teacher'">
          <h2 class="accordion-header" id="panelsStayOpen-headingFour">
            <button class="accordion-button" :class="{'collapsed' : $route.name !== 'Admin__Orders'}" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
              <i class="bi bi-wallet-fill me-1"></i>課程
            </button>
          </h2>
          <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse" :class="{'show' : $route.name == 'Admin__Orders'}" aria-labelledby="panelsStayOpen-headingFour">
            <div class="accordion-body text-start">
              <ul>
                <li><router-link class="dropdown-item" to="/admin/t/courses"><i class="bi bi-list-ul me-1"></i>課程列表</router-link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </aside>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Sidebar',
  props: ['toggled'],
  methods: {
    close() {
      this.$emit('close');
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userAuth', 'LoginProvider', 'role']),
  },
  created() {
    this.getUserAuthFromLocal();
    this.checkExpAuth();
  },
};
</script>
<style scoped lang="scss">
.active{
    color: cadetblue;
}
.collapse.show {
    position: unset !important;
    transform: translate3d(0px, 0px, 0px) !important;
}

.accordion-item:first-of-type
.accordion-button {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}

.accordion-button {
  background-color: transparent;
}

.accordion-button:not(.collapsed) {
  color: unset;
  background: transparent;
  box-shadow: unset;
}

.accordion-button:focus {
  border-color: transparent;
  box-shadow: unset;
}

.accordion-item  {
  background-color: transparent;
  border: none;
}

.accordion-button::after {
  width: 1rem;
  height: 1rem;
  background-size: 1rem;
}

.dropdown-item {
  background-color: transparent;
  color: black;
}

.sidebar-mb {
  height: 100%;
  overflow: auto;
  position: fixed;
  left: -280px;
  height: 100%;
  top: 0;
  z-index: 1050;
  width: 280px;
  min-width: 280px;
  transition: left .3s;
  &.toggled {
    left: 0;
  }
  &-close {
    text-align: end;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
}
</style>
